<nav #navbar class="navbar active header-class-top">
  <div class="container-fluid header-class" >
    <div class="row" style="position: relative; height: 100%;">
      <div class="col-3" style="height: 100% !important; position: relative;">
        <p class="text-end" style="width: 100%; height: 50%; position: relative;">
          <img src="assets/images/Logo-METFPA-2.png" style="width: 50%; height: 100%; position: relative;"/></p>
        <p class="text-end" style="width: 100%; height: 50%; position: relative;">
          <img src="assets/images/pejedec.jfif" style="width: 30%; height: 85%; position: relative; margin-right: 10%;"/></p>
      </div>
    <div class="col-6 align-header">
      <h2 class="header-message">
        Bienvenue sur le portail de pré-inscription du volet formation par apprentissage du PEJEDEC
      </h2>
    </div>
    <div class="col-3" style="height: 100% !important; position: relative;"> 
      <p style="width: 100%; height: 50%; position: relative;">
        <img src="assets/images/bankM.jpg" style="width: 50%; height: 115%; position: relative;"/></p>
      <p style="width: 100%; height: 50%; position: relative;">
        <img src="assets/images/LOGO-AGEFOP-HD.png" style="width: 35%; height: 88%; position: relative; margin-left: 10%;"/></p>
    </div>
  </div>
  </div>
</nav>
